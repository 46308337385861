import { Grid, Paper } from '@mui/material';
import * as React from 'react';

interface funcProp {
  getRegularPrice(token:string): any;
  setRegularPrice(price:string, token:string): any;
  mnAddress: string,
  usdtAddress: string,
}

const LandPrice = (Props:funcProp) => {
    
    const [regularUsdtPrice, setRegularUsdtPrice] = React.useState<any>('');
    const [regularMnPrice, setRegularMnPrice] = React.useState<any>('');

    React.useEffect(()=>{
      setRegularUsdtPrice(Props.getRegularPrice(Props.usdtAddress));
      setRegularMnPrice(Props.getRegularPrice(Props.mnAddress));
    },[]);

    return (
        <>
            <Grid item xs={6} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                  onClick={() => Props.setRegularPrice('222','0x337610d27c682e347c9cd60bd4b3b107c9d34ddd')}
                >
                   Set price
                </Paper>
              </Grid>
        </>
    );
};

export default LandPrice;