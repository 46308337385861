import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";

const User = () => {
    const [userList, setUserList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["userId", "userName", "email", "Wallet", "channel", "confirmMail", "create date", "latest login"];
    const [loading, setLoading] = React.useState<any>(true);
    const getUser = async () => {
        try {
            const userlist = await getUsers()
            setUserList(userlist.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    }
    const options = {
        onRowsDelete:(e:any)=>{console.log(e.data)},
    }

    React.useEffect(()=>{
        setLoading(true)
        getUser()
    },[]);

    return (
        <>  
        { !loading &&  <MUIDataTable 
                title={"Users List"} 
                data={userList.map((item:any) => {
                    let itemconfirmmail = ""
                    if (item.confirmMail){
                        itemconfirmmail = "true"
                    }else{
                        itemconfirmmail = "false"
                    }
                    return [
                        item.userId,
                        item.userName,
                        item.email,
                        item.wallet,
                        item.channel,
                        itemconfirmmail,
                        item.createdat,
                        item.latestlogindat
                    ]
                })}
                columns={columns}
                options={options}
            />
        }
            
        </>
    );
};

export default User;