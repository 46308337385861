import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { getLandSale, getLandBuyAmount, getTopBlockTokenId, getTopBlockBuyer } from "../api/jakaverseApi";
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { Box, TableContainer, Typography } from '@mui/material';
import Title from '../components/Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

const Dashboard = () => {
    const [landSale, setLandSale] = React.useState<any>();
    const [landBuyAmount, setLandBuyAmount] = React.useState<any>();
    const [topBlockTokenId, setTopBlockTokenId] = React.useState<any>();
    const [topBlockBuyer, setTopBlockBuyer] = React.useState<any>();

    const [loading, setLoading] = React.useState<any>(true);
    const navigate = useNavigate();

    const getLandSaleReport = async () => {
        try {
            const userlist = await getLandSale()
            setLandSale(userlist.data);

            const landBuyAmountList = await getLandBuyAmount()
            setLandBuyAmount(landBuyAmountList.data);

            const topBlockToken = await getTopBlockTokenId()
            setTopBlockTokenId(topBlockToken.data);

            const topBlockBuy = await getTopBlockBuyer()
            setTopBlockBuyer(topBlockBuy.data);

            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    }
    
    const numberWithCommas = (x:any) => {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    React.useEffect(()=>{
        setLoading(true)
        getLandSaleReport()
    },[]);

    return (
        <>
        { !loading &&  (
            <>
                <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Title>Land Sales</Title>
                    <Typography component="p" variant="h4">
                        {numberWithCommas(landSale.count)}
                    </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Title>Buy Amount</Title>
                    {landBuyAmount.map((data:any, idx:any)=>
                      <Box 
                      sx={{ display: 'flex', 
                            flexDirection: 'row',
                            justifyContent: 'center' 
                          }}
                      key={idx}
                    >
                        <Typography component="p">
                            { 
                            data.landDetailCurrency == '0x55d398326f99059fF775485246999027B3197955' ? 'USDT' : 'JK'
                            }
                        </Typography>
                        <Typography sx={{ ml:3 }} component="p" variant="h4">
                            {numberWithCommas(data.sum)}
                        </Typography>
                      </Box>
                    )}
                    
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Title>Count Blocks by Token ID</Title>
                  <TableContainer sx={{ maxHeight: 200 }}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Token Id</TableCell>
                          <TableCell>Blocks Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {topBlockTokenId.map((row:any, idx:any) => (
                          <TableRow key={idx}>
                            <TableCell>{row.landDetailTokenId}</TableCell>
                            <TableCell>{row.cou}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Title>Count Blocks by Wallet</Title>
                  <TableContainer sx={{ maxHeight: 200 }}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Blocks Count</TableCell>
                          <TableCell>Wallet</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Channel</TableCell>
                          <TableCell>Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {topBlockBuyer.map((row:any, idx:any) => (
                          <TableRow key={idx}>
                            <TableCell>{row.cou}</TableCell>
                            <TableCell>{row.landDetailOwnerWallet}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.userName}</TableCell>
                            <TableCell>{row.channel}</TableCell>
                            <TableCell>{row.userId}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                
                </Paper>
              </Grid>
            </>
        )}
        </>
    )
}

export default Dashboard;