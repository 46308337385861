import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAssets, createPartners, editPartners, getPartners } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, Switch, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSetRecoilState } from 'recoil';
import { snackBarAlertState } from '../recoil/atom';

const Partners = () => {
    const [partnerList, setPartnerList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["id", "name", "wallet", "detail", "isActive", "createDate", {
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <button onClick={() => {
              setId(tableMeta.rowData[0])
              setName(tableMeta.rowData[1])
              setWallet(tableMeta.rowData[2])
              setDetail(tableMeta.rowData[3])
              if (tableMeta.rowData[4] === "true"){
                setIsActive(true)
              } else {
                setIsActive(false)
              }
              handleClickOpenEdit()
              }}>
              Edit
            </button>
          );
        }
      }
    },];
    const [loading, setLoading] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);

    const [id, setId] = React.useState<number>(0);
    const [name, setName] = React.useState<string>("");
    const [wallet, setWallet] = React.useState<string>("");
    const [detail, setDetail] = React.useState<string>("");
    const [isActive, setIsActive] = React.useState<boolean>(true);
    const [image, setImage] = React.useState<File>();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpenEdit = () => {
      setOpenEdit(true);
    };

    const handleCloseEdit = () => {
      setOpenEdit(false);
    };

    const resetForm = () => {
      setId(0)
      setName("")
      setWallet("")
      setDetail("")
      setIsActive(true)
    };

    const handleIsActive = (event:any) => {
      setIsActive(event.target.checked)
    }

    const getPartner = async () => {
        try {
            const partnerList = await getPartners()
            setPartnerList(partnerList.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    };

    const submitAddPartner = async (dataSubmit:any) => {
      try {
        const response = await createPartners(dataSubmit);
        window.location.reload();
        if (response.data) {
          getPartner();
        } else {
          setSnackBarAlertState({
            open:true,
            severity:'error',
            message:'Something wrong'
          })
        }
      }catch (error:any) {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }

    const submitEditPartner = async (dataSubmit:any, id:number) => {
      try {
        const response = await editPartners(dataSubmit, id);
        window.location.reload();
        if (response.data) {
          getPartner();
        } else {
          setSnackBarAlertState({
            open:true,
            severity:'error',
            message:'Something wrong'
          })
        }
      }catch (error:any) {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const dataSubmit = {
        name: name,
        detail: detail,
        wallet: wallet,
      };
      let formData = new FormData();
      if (image) {
        formData.append('file', image);
      }
      formData.append('name', name);
      formData.append('detail', detail);
      formData.append('wallet', wallet);
      submitAddPartner(formData);
    };

    const handleSubmitEdit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const dataSubmit = {
        name: name,
        detail: detail,
        wallet: wallet,
        isActive: isActive,
      };
      if (id > 0) {
        let formData = new FormData();
        if (image) {
          formData.append('file', image);
        }
        formData.append('name', name);
        formData.append('detail', detail);
        formData.append('wallet', wallet);
        formData.append('isActive', isActive.toString());
        submitEditPartner(formData, id);
      }else{
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    };

    const options = {
      customToolbar: () => {
        return (
          <>
            <IconButton onClick={()=>{resetForm(); handleClickOpen();}} >
              <AddIcon/>
            </IconButton>

              <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Add Partner</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="wallet"
                    label="wallet"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={wallet}
                    onChange={(e) => {
                      setWallet(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="detail"
                    label="Detail"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="standard"
                    value={detail}
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="image"
                    label="Image"
                    type="file"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const target = e.target as HTMLInputElement;
                      if (target && target.files) {
                        setImage(target.files[0]);
                      }
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit">Add Partner</Button>
                </DialogActions>
                </Box>
              </Dialog>


              <Dialog open={openEdit} onClose={handleCloseEdit}>
                <Box component="form" onSubmit={handleSubmitEdit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Update Partner</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="wallet"
                    label="wallet"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={wallet}
                    onChange={(e) => {
                      setWallet(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="detail"
                    label="Detail"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="standard"
                    value={detail}
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="image"
                    label="Image"
                    type="file"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const target = e.target as HTMLInputElement;
                      if (target && target.files) {
                        setImage(target.files[0]);
                      }
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch checked={isActive} onChange={handleIsActive} />
                      } label="Active" />
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseEdit}>Cancel</Button>
                  <Button type="submit">Update Partner</Button>
                </DialogActions>
                </Box>
              </Dialog>
          </>
        );
      }
    };

    React.useEffect(()=>{
        setLoading(true)
        getPartner()
    },[]);

    return (
        <>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            { !loading &&
              <MUIDataTable
                title={"Partners List"}
                data={partnerList.map((item:any) => {
                    let isActive = ""
                    if (item.Partner_isActive){
                        isActive = "true"
                    }else{
                        isActive = "false"
                    }
                    return [
                        item.Partner_id,
                        item.Partner_name,
                        item.Partner_wallet,
                        item.Partner_detail,
                        isActive,
                        item.Partner_createDate,
                    ]
                })}
                columns={columns}
                options={options}
              />
            }
            </div>
        </>
    );
};

export default Partners;
