import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAssets, getAssets } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSetRecoilState } from 'recoil';
import { snackBarAlertState } from '../recoil/atom';

const Assets = () => {
    const [assetList, setAssetList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["assetsId", "TokenId", "Name", "Image Path", "link", "Youtube", "owner", "description", "height", "width", "landCordx", "landCordy", {
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <button onClick={() => {
              console.log(value)
              console.log(tableMeta)
              setTokenId(tableMeta.rowData[1])
              setName(tableMeta.rowData[2])
              setFilepath(tableMeta.rowData[3])
              setWebsite(tableMeta.rowData[4])
              setYoutube(tableMeta.rowData[5])
              setDescription(tableMeta.rowData[7])
              handleClickOpen()
              }}>
              Edit
            </button>
          );
        }
      }
    },];
    const [loading, setLoading] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);

    const [name, setName] = React.useState<string>("");
    const [tokenId, setTokenId] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [website, setWebsite] = React.useState<string>("");
    const [youtube, setYoutube] = React.useState<string>("");
    const [image, setImage] = React.useState<File>();
    const [filepath, setFilepath] = React.useState<string>("");

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    const resetForm = () => {
      setTokenId("")
      setName("")
      setFilepath("")
      setWebsite("")
      setYoutube("")
    };

    const getAsset = async () => {
        try {
            const assetList = await getAssets()
            setAssetList(assetList.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    };

    const submitAddAsset = async (dataSubmit:any, tokenId:string) => {
      try {
        const response = await createAssets(dataSubmit, tokenId);
        if (response.data) {
          window.location.reload();
        } else {
          setSnackBarAlertState({
            open:true,
            severity:'error',
            message:'Something wrong'
          })
        }
      }catch (error:any) {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const dataSubmit = {
        name: name,
        tokenid: tokenId,
        description: description,
        link: website,
        youtube: youtube,
        file: image,
        filepath: filepath,
      };

      let formData = new FormData();
      if (image) {
        formData.append('file', image);
      }
      formData.append('tokenid', tokenId);
      formData.append('name', name);
      formData.append('youtube', youtube);
      formData.append('link', website);
      formData.append('description', description);
      formData.append('filepath', filepath);
      submitAddAsset(formData, tokenId);
    };

    const options = {
      customToolbar: () => {
        return (
          <>
            <IconButton onClick={()=>{resetForm(); handleClickOpen();}} >
              <AddIcon/>
            </IconButton>

              <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Add Asset</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="tokenId"
                    label="Token Id"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={tokenId}
                    onChange={(e) => {
                      setTokenId(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="standard"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="website"
                    label="Website"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={website}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="youtube"
                    label="Youtube"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={youtube}
                    onChange={(e) => {
                      setYoutube(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="image"
                    label="Image"
                    type="file"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const target = e.target as HTMLInputElement;
                      if (target && target.files) {
                        setImage(target.files[0]);
                      }
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit">Add Asset</Button>
                </DialogActions>
                </Box>
              </Dialog>
          </>
        );
      }
    };

    React.useEffect(()=>{
        setLoading(true)
        getAsset()
    },[]);

    return (
        <>
        { !loading &&
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MUIDataTable
                title={"Assets List"}
                data={assetList.map((item:any) => {
                    return [
                        item.asset_assetsId,
                        item.asset_tokenId,
                        item.asset_name,
                        item.asset_imagepath,
                        item.asset_link,
                        item.asset_youtube,
                        item.asset_owner,
                        item.asset_description,
                        item.asset_height,
                        item.asset_width,
                        item.asset_landCordx,
                        item.asset_landCordy
                    ]
                })}
                columns={columns}
                options={options}
              />
            </div>

        }

        </>
    );
};

export default Assets;
