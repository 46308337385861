import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getEvents } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";

const Event = () => {
    const [userList, setUserList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["eventsId", "transactionHash", "status", "address", "blockNumber", "event", "create date", "values"];
    const [loading, setLoading] = React.useState<any>(true);
    const getUser = async () => {
        try {
            const userlist = await getEvents()
            setUserList(userlist.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    }

    React.useEffect(()=>{
        setLoading(true)
        getUser()
    },[]);

    return (
        <>
        { !loading &&
          <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MUIDataTable
                title={"Events List"}
                data={userList.map((item:any) => {
                    return [
                        item.eventsId,
                        item.transactionHash,
                        item.status,
                        item.address,
                        item.blockNumber,
                        item.event,
                        item.createdat,
                        item.returnValues
                    ]
                })}
                columns={columns}
            />
          </div>
        }

        </>
    );
};

export default Event;
