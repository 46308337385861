import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getStaking } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";

const StakingList = () => {
    const [userList, setUserList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["id", "wallet", "tokenId", "landAmount", "value", "stakingDate", "status", "stakingType", "nextRedeemDate", "interestReceived", "withdrawDate", "transactionHashStaking", "transactionHashWithdraw"];
    const [loading, setLoading] = React.useState<any>(true);
    const getUser = async () => {
        try {
            const userlist = await getStaking()
            setUserList(userlist.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    }

    React.useEffect(()=>{
        setLoading(true)
        getUser()
    },[]);

    return (
        <>
        { !loading &&
          <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MUIDataTable
                title={"Staking List"}
                data={userList.map((item:any) => {
                    return [
                        item.id,
                        item.wallet,
                        item.tokenId,
                        item.landAmount,
                        item.landValue,
                        item.stakingDate,
                        item.status,
                        item.stakingType,
                        item.nextRedeemDate,
                        item.interestReceived,
                        item.withdrawDate,
                        item.transactionHashStaking,
                        item.transactionHashWithdraw,
                    ]
                })}
                columns={columns}
            />
          </div>
        }

        </>
    );
};

export default StakingList;
