/* eslint-disable jest/no-mocks-import */
import axios from "axios";
import { User } from "../typings/model";
import config from "../config/config.json";

export const apiAxios = axios.create({
  baseURL: `${config.api_host}`,
});

export const getUsers = () => {
  return apiAxios.get<User[]>(`/users`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getEvents = () => {
  return apiAxios.get<User[]>(`/events`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getLandDetail = () => {
  return apiAxios.get<User[]>(`/land-detail/getlanddetail`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getLandSale = () => {
  return apiAxios.get<any[]>(`/land-detail/getlandsale`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getLandBuyAmount = () => {
  return apiAxios.get<any[]>(`/land-detail/getlandbuyamount`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getTopBlockTokenId = () => {
  return apiAxios.get<any[]>(`/land-detail/gettopblocktokenid`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getTopBlockBuyer = () => {
  return apiAxios.get<any[]>(`/land-detail/gettopblockbuyer`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getAssets = () => {
  return apiAxios.get<any[]>(`/assets/admin`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const createAssets = (data:any, tokenid:string) => {
  return apiAxios.post<any[]>(`/assets/admin/${tokenid}`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getPartners = () => {
  return apiAxios.get<any[]>(`/partners/admin`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const createPartners = (data:any) => {
  return apiAxios.post<any[]>('/partners/admin', data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const editPartners = (data:any, id:number) => {
  return apiAxios.patch<any[]>(`/partners/${id}/admin`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const signIn = (data: any) => {
  return apiAxios.post("/admin/login", data);
};

export const getStaking = () => {
  return apiAxios.get<any[]>(`/staking/admin`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const getStakingSetting = () => {
  return apiAxios.get<any[]>(`/staking-setting/alladmin`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const createStakingSetting = (data:any) => {
  return apiAxios.post<any[]>('/staking-setting/alladmin', data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};

export const editStakingSetting = (data:any, id:number) => {
  return apiAxios.patch<any[]>(`/staking-setting/${id}/alladmin`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem("access_token")
    }
  });
};
