import React from 'react';
import { Routes, Route, Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import './App.css';
import Layout from "./layout/Layout";

import Home from "./components/Home";
import User from "./pages/User";
import Event from "./pages/Event";
import Signin from "./pages/Signin";
import SmartContract from "./components/SmartContract";

import SnackBarAlert from './components/SnackBarAlert';
import Land from './pages/Land';
import Dashboard from './pages/Dashboard';
import Assets from './pages/Assets';
import Partners from './pages/Partners';
import StakingList from './pages/StakingList';
import StakingDashboard from './pages/StakingDashboard';
import StakingSettings from './pages/StakingSettings';


interface AuthContextType {
  user: any;
}
let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);
  let value = { user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return JSON.parse(localStorage.getItem("userInfo") || "{}");
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isLogin) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <div className="App">
      <SnackBarAlert />
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<Signin />} />
            <Route element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
                }>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<User />} />
              <Route path="/lands" element={<Land />} />
              <Route path="/events" element={<Event />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/staking-list" element={<StakingList />} />
              <Route path="/staking-dashboard" element={<StakingDashboard />} />
              <Route path="/staking-setting" element={<StakingSettings />} />
              <Route path="/smartcontract" element={<SmartContract />} />
            </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
