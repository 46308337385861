import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useRecoilState } from "recoil";
import { snackBarAlertState } from "../recoil/atom";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const SnackbarAlert = () => {


    const handleClose = () => {
        setSnackBarAlert({
            open:false,
            severity:SnackBarAlert.severity,
            message:SnackBarAlert.message
        });
    };
    
    const [SnackBarAlert, setSnackBarAlert] = useRecoilState(snackBarAlertState);

    return (
        <>
            <Snackbar
                open={SnackBarAlert.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                
                <Alert 
                    onClose={handleClose} 
                    sx={{ width: '100%' }} 
                    severity={SnackBarAlert.severity}
                >
                    {SnackBarAlert.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SnackbarAlert