import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAssets, createPartners, createStakingSetting, editPartners, editStakingSetting, getStakingSetting } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, Switch, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSetRecoilState } from 'recoil';
import { snackBarAlertState } from '../recoil/atom';

const StakingSettings = () => {
    const [partnerList, setPartnerList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["id", "description", "days", "minimumValue", "maximumValue", "minimumLand", "maximumLand", "limitValue", "limitLand", 'interestRate', "isActive", {
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <button onClick={() => {
              setId(tableMeta.rowData[0])
              setDescription(tableMeta.rowData[1])
              setDays(tableMeta.rowData[2])
              setMinimumValue(tableMeta.rowData[3])
              setMaximumValue(tableMeta.rowData[4])
              setMinimumLand(tableMeta.rowData[5])
              setMaximumLand(tableMeta.rowData[6])
              setLimitValue(tableMeta.rowData[7])
              setLimitLand(tableMeta.rowData[8])
              setInterestRate(tableMeta.rowData[9])
              if (tableMeta.rowData[10] === "true"){
                setIsActive(true)
              } else {
                setIsActive(false)
              }
              handleClickOpenEdit()
              }}>
              Edit
            </button>
          );
        }
      }
    },];
    const [loading, setLoading] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);

    const [id, setId] = React.useState<number>(0);
    const [description, setDescription] = React.useState<string>("");
    const [days, setDays] = React.useState<number>(0);
    const [minimumValue, setMinimumValue] = React.useState<number>(0);
    const [maximumValue, setMaximumValue] = React.useState<number>(0);
    const [minimumLand, setMinimumLand] = React.useState<number>(0);
    const [maximumLand, setMaximumLand] = React.useState<number>(0);
    const [limitValue, setLimitValue] = React.useState<number>(0);
    const [limitLand, setLimitLand] = React.useState<number>(0);
    const [interestRate, setInterestRate] = React.useState<number>(0);
    const [isActive, setIsActive] = React.useState<boolean>(true);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpenEdit = () => {
      setOpenEdit(true);
    };

    const handleCloseEdit = () => {
      setOpenEdit(false);
    };

    const resetForm = () => {
      setId(0)
      setDescription("")
      setDays(0)
      setMinimumValue(0)
      setMaximumValue(0)
      setMinimumLand(0)
      setMaximumLand(0)
      setLimitValue(0)
      setLimitLand(0)
      setInterestRate(0)
      setIsActive(true)
    };

    const handleIsActive = (event:any) => {
      setIsActive(event.target.checked)
    }

    const getPartner = async () => {
        try {
            const partnerList = await getStakingSetting()
            setPartnerList(partnerList.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    };

    const submitAddPartner = async (dataSubmit:any) => {
      try {
        const response = await createStakingSetting(dataSubmit);
        window.location.reload();
        if (response.data) {
          getPartner();
        } else {
          setSnackBarAlertState({
            open:true,
            severity:'error',
            message:'Something wrong'
          })
        }
      }catch (error:any) {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }

    const submitEditPartner = async (dataSubmit:any, id:number) => {
      try {
        const response = await editStakingSetting(dataSubmit, id);
        window.location.reload();
        if (response.data) {
          getPartner();
        } else {
          setSnackBarAlertState({
            open:true,
            severity:'error',
            message:'Something wrong'
          })
        }
      }catch (error:any) {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const dataSubmit = {
        description: description,
        days: days,
        minimumValue: minimumValue,
        maximumValue: maximumValue,
        minimumLand: minimumLand,
        maximumLand: maximumLand,
        limitValue: limitValue,
        limitLand: limitLand,
        interestRate: interestRate,
        isActive: isActive,
      };
      submitAddPartner(dataSubmit);
    };

    const handleSubmitEdit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const dataSubmit = {
        description: description,
        days: days,
        minimumValue: minimumValue,
        maximumValue: maximumValue,
        minimumLand: minimumLand,
        maximumLand: maximumLand,
        limitValue: limitValue,
        limitLand: limitLand,
        interestRate: interestRate,
        isActive: isActive,
      };
      if (id > 0) {
        submitEditPartner(dataSubmit, id);
      }else{
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    };

    const options = {
      customToolbar: () => {
        return (
          <>
            <IconButton onClick={()=>{resetForm(); handleClickOpen();}} >
              <AddIcon/>
            </IconButton>

              <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Add Staking</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="days"
                    label="Days"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={days}
                    onChange={(e) => {
                      setDays(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="minimumValue"
                    label="MinimumValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={minimumValue}
                    onChange={(e) => {
                      setMinimumValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="maximumValue"
                    label="MaximumValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={maximumValue}
                    onChange={(e) => {
                      setMaximumValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="minimumLand"
                    label="MinimumLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={minimumLand}
                    onChange={(e) => {
                      setMinimumLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="maximumLand"
                    label="MaximumLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={maximumLand}
                    onChange={(e) => {
                      setMaximumLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="limitValue"
                    label="LimitValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={limitValue}
                    onChange={(e) => {
                      setLimitValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="limitLand"
                    label="LimitLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={limitLand}
                    onChange={(e) => {
                      setLimitLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="interestRate"
                    label="InterestRate"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={interestRate}
                    onChange={(e) => {
                      setInterestRate(Number(e.target.value));
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch checked={isActive} onChange={handleIsActive} />
                      } label="Active" />
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit">Add Staking</Button>
                </DialogActions>
                </Box>
              </Dialog>


              <Dialog open={openEdit} onClose={handleCloseEdit}>
                <Box component="form" onSubmit={handleSubmitEdit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Update Staking</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="days"
                    label="Days"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={days}
                    onChange={(e) => {
                      setDays(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="minimumValue"
                    label="MinimumValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={minimumValue}
                    onChange={(e) => {
                      setMinimumValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="maximumValue"
                    label="MaximumValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={maximumValue}
                    onChange={(e) => {
                      setMaximumValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="minimumLand"
                    label="MinimumLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={minimumLand}
                    onChange={(e) => {
                      setMinimumLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="maximumLand"
                    label="MaximumLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={maximumLand}
                    onChange={(e) => {
                      setMaximumLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="limitValue"
                    label="LimitValue"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={limitValue}
                    onChange={(e) => {
                      setLimitValue(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="limitLand"
                    label="LimitLand"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={limitLand}
                    onChange={(e) => {
                      setLimitLand(Number(e.target.value));
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="interestRate"
                    label="InterestRate"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={interestRate}
                    onChange={(e) => {
                      setInterestRate(Number(e.target.value));
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch checked={isActive} onChange={handleIsActive} />
                      } label="Active" />
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseEdit}>Cancel</Button>
                  <Button type="submit">Update Staking</Button>
                </DialogActions>
                </Box>
              </Dialog>
          </>
        );
      }
    };

    React.useEffect(()=>{
        setLoading(true)
        getPartner()
    },[]);

    return (
        <>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            { !loading &&
              <MUIDataTable
                title={"Staking Settings"}
                data={partnerList.map((item:any) => {
                    let isActive = ""
                    if (item.isActive){
                        isActive = "true"
                    }else{
                        isActive = "false"
                    }
                    return [
                        item.id,
                        item.description,
                        item.days,
                        item.minimumValue,
                        item.maximumValue,
                        item.minimumLand,
                        item.maximumLand,
                        item.limitValue,
                        item.limitLand,
                        item.interestRate,
                        isActive,
                    ]
                })}
                columns={columns}
                options={options}
              />
            }
            </div>
        </>
    );
};

export default StakingSettings;
