import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getLandDetail } from "../api/jakaverseApi";
import MUIDataTable from "mui-datatables";

const Land = () => {
    const [userList, setUserList] = React.useState<any>("");
    const navigate = useNavigate();
    const columns = ["Id", "JAKA ID", "Owner", "Buy amount", "currency", "Sell amount", "currency", "create date", "status", "land id", "x", "y", "section", "landData", "landType"];
    const [loading, setLoading] = React.useState<any>(true);
    const getUser = async () => {
        try {
            const userlist = await getLandDetail()
            setUserList(userlist.data);
            setLoading(false)
        }catch (error:any){
            if (error.response.status === 401){
                localStorage.removeItem('userInfo');
                localStorage.removeItem('access_token');
                navigate('/', { replace: true })
            }
        }
    }

    React.useEffect(()=>{
        setLoading(true)
        getUser()
    },[]);

    return (
        <>
        { !loading &&
          <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MUIDataTable
                title={"Lands List"}
                data={userList.map((item:any) => {
                    let currency = ""
                    let sellcurrency = ""
                    if (item.landDetail_landDetailCurrency){
                        if (item.landDetail_landDetailCurrency == '0x55d398326f99059fF775485246999027B3197955'){
                            currency = "USDT"
                        }else{
                            currency = "JK"
                        }
                    }

                    if (item.landDetail_landDetailSellCurrency) {
                        if (item.landDetail_landDetailSellCurrency == '0x55d398326f99059fF775485246999027B3197955'){
                            sellcurrency = "USDT"
                        }else{
                            sellcurrency = "JK"
                        }
                    }

                    return [
                        item.landDetail_landDetailId,
                        item.landDetail_landDetailTokenId,
                        item.landDetail_landDetailOwnerWallet,
                        item.landDetail_landDetailAmount,
                        currency,
                        item.landDetail_landDetailSellAmount,
                        sellcurrency,
                        item.landDetail_createdat,
                        item.landDetail_landDetailStatus,
                        item.land_landId,
                        item.land_landCordx,
                        item.land_landCordy,
                        item.land_landSector,
                        item.land_landData,
                        item.land_landType
                    ]
                })}
                columns={columns}
            />
          </div>
        }

        </>
    );
};

export default Land;
