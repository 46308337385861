import { Button, Grid, Paper } from '@mui/material';
import * as React from 'react';
import Web3 from "web3";

import { snackBarAlertState } from "../../recoil/atom";
import { useSetRecoilState } from "recoil";

import mn from "../../config/MN.json";
import mnLand from "../../config/Mnland.json";
import mnVerse from "../../config/Mnverse.json";
import usdt from "../../config/Usdt.json";

import LandPrice from "./LandPrice";

declare var window: any

const SmartContract = () => {
    const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);
    const [walletAccount, setWalletAccount] = React.useState<string>('');
    const [web3Obj, setWeb3Obj] = React.useState<any>('');
    const [mnVerseContract, setMnVerseContract] = React.useState<any>('');

    const connectWallet = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
          } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
          } else {
            setSnackBarAlertState({
                open:true,
                severity:'error',
                message:'Your browser is not supported'
            })
            return;
          }

        const web3 = window.web3;
        if (web3) {
            const accounts = await web3.eth.getAccounts();
            setWalletAccount(accounts[0]);
            setWeb3Obj(web3)
            const mnToken = await new web3.eth.Contract(
                mn.abi,
                mn.address
            );
            const usdtToken = await new web3.eth.Contract(
                usdt.abi,
                usdt.address
            );
            const mnVerseToken = await new web3.eth.Contract(
                mnVerse.abi,
                mnVerse.address
            );
            const mnLandToken = await new web3.eth.Contract(
                mnLand.abi,
                mnLand.address
            );

            if (mnVerseToken) {
                setMnVerseContract(mnVerseToken)
            }
        }
    };

    const getRegularPrice = async (token:string) => {
        return await mnVerseContract.methods.pricePerPoint(token).call();
    }

    const setRegularPrice = async (price:string, token:string) => {
        const setPrice = web3Obj.utils.toWei(price)
        const pricePerPoint = await mnVerseContract.methods.setPricePerPoint(setPrice, token).send({
            from : walletAccount
          }).on('transactionHash', (transactionHash:any) => {
            setSnackBarAlertState({
                open:true,
                severity:'success',
                message: transactionHash.toString()
            })
          })
          .on('error', (error:any) => {
            setSnackBarAlertState({
                open:true,
                severity:'error',
                message: error.message
            })
          })
          .catch((error:any)=>{
            setSnackBarAlertState({
                open:true,
                severity:'error',
                message: error.message
            })
          })
    };

    React.useEffect(()=>{
        connectWallet()
    },[]);
    
    return (
        <>
            {
                walletAccount === "" && (
                    <Grid item xs={12} md={4} lg={3}>
                        <Button 
                            onClick={()=>connectWallet()}
                            variant="contained"
                            >Connect Wallet</Button>
                    </Grid>
                )
            }
            {
                walletAccount && mnVerseContract && (
                    <>
                        <Grid item xs={12}>
                            <p>Connected : {walletAccount}</p>
                        </Grid>
                        <LandPrice 
                            getRegularPrice={getRegularPrice}
                            setRegularPrice={setRegularPrice}
                            mnAddress={mn.address}
                            usdtAddress={usdt.address}
                        />
                    </>
                    
                )
            }
            
        </>
    );
};

export default SmartContract;